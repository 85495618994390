<template lang="pug">
  v-dialog(
    max-width="350"
    v-model="showModal")
      v-card
        v-card-title
          h2.card-title {{ 'modals.planning.allow.title' | translate }}

        v-card-content {{ 'modals.planning.allow.message' | translate }}

        v-card-actions
          v-btn.mr-3(
            @click="_confirm")
            | {{ 'actions.allow' | translate }}
          v-btn.ml-3(
            secondary
            style="ml-10"
            @click="_cancel")
            | {{ 'actions.cancel' | translate }}
</template>

<script>

export default {
  name: 'StartAnyWayModal',

  data: () => ({
    resolvePromise: undefined,
    rejectPromise: undefined,
    showModal: false
  }),

  methods: {
      show () {
        this.showModal = true
        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve
          this.rejectPromise = reject
        })
      },

      _confirm () {
        this.showModal = false
        this.resolvePromise(true)
      },

      _cancel () {
        this.showModal = false
        this.resolvePromise(false)
      }
  }
}
</script>

<style lang="scss" scoped>

</style>
