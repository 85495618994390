<template lang="pug">
  v-card-content
    v-date-field(
      :date-from="filterMinDate"
      :date-to="filterMaxDate"
      :label="$t('base.date')"
      v-model="form.date")

    v-select.mb-3(
      :label="$t('base.ration')"
      :options="rationsList"
      v-model="form.ration")

    v-select.mb-3(
      :label="$t('base.recipe')"
      :options="recipes"
      v-model="form.recipe")

    v-switch.mr-1(
        :label="$t('modals.planning.create.require_qr_code')"
        v-model="form.require_qr_code")

    v-switch.mr-1(
        :label="$t('modals.planning.create.require_queue')"
        v-model="form.require_queue")

    v-card.mb-4(secondary)
      v-card-title
        .cols
          .col {{ 'base.silage' | translate }}
          .col {{ 'base.weight' | translate }}

      v-card-content
        .cols(
          v-for="(item, index) in form.silages"
          :key="index")
          .col
            v-select(
              :label="$t('base.silage')"
              :options="silages"
              @input="selectedSilage"
              item-name="number"
              v-model="item.id")
          .col
            v-text-field(
              :label="$t('base.weight') + '[' + $t('units.kg') +']'"
              v-model="item.weight")
            v-btn(
              v-if="form.silages.length > 1"
              icon
              small
              @click="removeSilage(item)")
              i.i-close

      v-card-actions
        v-btn(
          small
          secondary
          @click="addSilage")
          v-icon(
            position-left
            icon="plus")
          span {{ 'actions.add_silage' | translate }}

    v-text-field.mb-2(
      :label="$t('pages.settings.mixing_time')"
      v-model="form.mixing_time")

    p.text-danger.mb-2(v-if="hasNotMatchRecipe") {{ 'messages.recipe_does_not_mach' | translate }}
    start-anyway-modal(ref="startAnywayModal")

    v-card-actions
      v-btn(
        :disabled="!isRationValid || !isDateSelected || !isRecipeSelected || !isAllSilagesSelected"
        :loading="loading"
        @click="onUpdate").mr-2 {{ 'actions.save' | translate }}
      delete-btn(
        ref="deleteBtn"
        :loading="deleting"
        @delete="onDelete")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import DeleteBtn from '@/components/common/delete-btn/DeleteBtn'
import StartAnywayModal from '@/components/common/start-anyway-modal/StartAnywayModal'

export default {
  name: 'EditPlanningModal',

  components: {
    DeleteBtn,
    StartAnywayModal
  },

  props: {
    item: Object
  },

  data: () => ({
    form: {
      ration: null,
      date: null,
      recipe: null,
      require_qr_code: false,
      require_queue: false,
      silages: [
        { id: null, weight: 0 }
      ],
      mixing_time: 0,
      is_started_anyway: false
    },
    toggle: {
      allowChoice: false
    },
    loading: false,
    deleting: false
  }),

  computed: {
    ...mapGetters([
      'rationsList',
      'recipesList',
      'silagesList'
    ]),

    isRationValid () {
      return !!this.form.ration
    },

    isDateSelected () {
      return this.form.date !== null
    },

    isRecipeSelected () {
      return this.form.recipe !== null
    },

    isAllSilagesSelected () {
      return !!this.form.silages.length
    },

    recipes () {
      return this.recipesList.filter(item => item.ration_id === this.form.ration)
    },

    silages () {
      return this.silagesList.filter(item => item.ration_id === this.form.ration)
    },

    filterMinDate () {
      return new Date()
    },

    filterMaxDate () {
      return new Date(new Date().setDate(new Date().getDate() + 6))
    },

    hasNotMatchRecipe () {
      if (!this.form.recipe) {
        return false
      }

      const notMatchSilages = this.form.silages
        .filter(silage => {
          const selectedSilage = this.silagesList.find(item => item.id === silage.id)
          if (selectedSilage) {
            return selectedSilage.lastPlannedRecipeID && selectedSilage.lastPlannedRecipeID !== this.form.recipe
          } else {
            return false
          }
        })

      return notMatchSilages.length
    }
  },

  async mounted () {
    await this.fetchRationsList()
    await this.fetchSilagesList()
    await this.fetchRecipesList({ status: true })
    this.form.date = this.item.date
    this.form.ration = this.item.ration.id
    this.form.recipe = this.item.recipe.id
    this.form.require_qr_code = this.item.require_qr_code
    this.form.require_queue = this.item.require_queue
    this.form.silages = this.item.silages_list.map(silage => ({
      id: silage.id,
      weight: this.item.plan_weight
    }))
    this.form.mixing_time = this.item.mixing_time
    this.toggle.allowChoice = this.item.change
  },

  methods: {
    ...mapActions([
      'fetchRecipesList',
      'fetchSilagesList',
      'fetchRationsList',
      'updateTimetable',
      'deleteTimetable'
    ]),

    addSilage () {
      this.form.silages.push({
        id: null,
        weight: 0
      })
    },

    removeSilage (item) {
      const index = this.form.silages
        .indexOf(item)
      if (index !== -1) {
        this.form.silages.splice(index, 1)
      }
    },

    selectedSilage (silageID) {
      const selectedSilage = this.silagesList.find(silage => silage.id === silageID)
      this.form.silages.forEach(function (silage) {
        if (silage.id === selectedSilage.id) {
          silage.weight = selectedSilage.max_weight - selectedSilage.net_weight
        }
      })
    },

    async onUpdate () {
      if (this.hasNotMatchRecipe) {
        this.form.is_started_anyway = await this.$refs.startAnywayModal.show()
      }

      if (!this.hasNotMatchRecipe || this.form.is_started_anyway) {
        this.loading = true
        const payload = {
          ration_id: this.form.ration,
          planned_at: this.form.date,
          recipe_id: this.form.recipe,
          require_qr_code: this.form.require_qr_code,
          require_queue: this.form.require_queue,
          mixing_time: this.form.mixing_time,
          change: this.toggle.allowChoice,
          silages_ids: this.form.silages,
          is_started_anyway: this.form.is_started_anyway
        }
        const { errors } = await this.$store.dispatch('updateTimetable', {
          id: this.item.timetable_id,
          data: payload
        })
        this.loading = false
        if (!errors) {
          this.$emit('close')
        } else {
          for (const key in errors) {
            for (const msg of errors[key]) {
              this.$toasted.show(msg, {
                position: 'bottom-center',
                duration: 5000
              })
            }
          }
        }
      }
    },

    async onDelete () {
      this.deleting = true
      const { success } = await this.$store.dispatch('deleteTimetable', this.item)
      this.deleting = false
      if (success) {
        this.$refs.deleteBtn.close()
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cols {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .col {
    &:first-child {
      width: calc(70% - 10px);
    }

    &:last-child {
      display: flex;
      align-items: center;
      width: calc(30% - 10px);
    }
  }
}
.v-switch__wrapper {
  margin: 10px 0;
}
</style>
